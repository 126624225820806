export const tugas1 = [
    {
      id: 1,
      image: "/foto_kelas/2.jpg",
      nama: "Adhitya Pramana Putra",
      npm: "062230701516",
      tugas: "data/Adhitya Pramana Putra/6_Software_Adhitya_Pramana.pdf", // Tambahkan informasi tugas di sini
      delay: "200",
    },
    {
      id: 2,
      image: "/foto_kelas/3.jpg",
      nama: "Ahmad Perdian",
      npm: "062230701517",
      tugas: "data/Ahmad Perdian/Analisis 6 Software Perawatan_Ahmad Perdian.pdf", // Tambahkan informasi tugas di sini
      delay: "400",
    },
    {
      id: 3,
      image: "/foto_kelas/4.jpg",
      nama: "Arif Wijaya",
      npm: "062230701519",
      tugas: "data/Arif Wijaya/062230701519_Arif WIjaya.pdf", // Tambahkan informasi tugas di sini
      delay: "",
    },
    {
      id: 4,
      image: "/foto_kelas/5.jpg",
      nama: "Clodiya Novita Sari",
      npm: "062230701520",
      tugas: "data/Clodiya Novita Sari/Clodiya Novita Sari-Tugas 1.pdf", // Tambahkan informasi tugas di sini
      delay: "200",
    },
    {
      id: 5,
      image: "/foto_kelas/6.jpg",
      nama: "Elda Oktarina",
      npm: "062230701521",
      tugas: "data/Elda Oktarina/Tugas 1 - Elda Oktarina.pdf", // Tambahkan informasi tugas di sini
      delay: "400",
    },
    {
      id: 6,
      image: "/foto_kelas/7.jpg",
      nama: "Fauzan Ramadhan",
      npm: "062230701522",
      tugas: "data/Fauzan Ramadhan/Analisis 6 Software Perawatan_Fauzan Ramadhan.pdf", // Tambahkan informasi tugas di sini
      delay: "",
    },
    {
      id: 7,
      image: "/foto_kelas/8.jpg",
      nama: "Hari Wijaya",
      npm: "062230701523",
      tugas: "data/Hari Wijaya/Tugas1_HariWijaya.pdf", // Tambahkan informasi tugas di sini
      delay: "200",
    },
    {
      id: 8,
      image: "/foto_kelas/9.jpg",
      nama: "M.Zakky Al Wafa",
      npm: "062230701525",
      tugas: "data/M.Zakky Al Wafa/Me-ReView6Software_MZakkyAlWafa.pdf", // Tambahkan informasi tugas di sini
      delay: "400",
    },
    {
      id: 9,
      image: "/foto_kelas/10.jpg",
      nama: "Ghibran Adean",
      npm: "062230701526",
      tugas: "data/Ghibran Adean/M.GhibranAdean Tugas-1.pdf", // Tambahkan informasi tugas di sini
      delay: "",
    },
    {
      id: 10,
      image: "/foto_kelas/11.jpg",
      nama: "M Ade Ilham W",
      npm: "062230701527",
      tugas: "data/M Ade Ilham W/Muhammad Ade Ilham Wahyudi.pdf", // Tambahkan informasi tugas di sini
      delay: "200",
    },
    {
      id: 11,
      image: "/foto_kelas/12.jpg",
      nama: "Muhammad Farhan",
      npm: "062230701528",
      tugas: "data/Muhammad Farhan/MuhammadFarhan_6 Software yang dapat Menganalisa Komputer.pdf", // Tambahkan informasi tugas di sini
      delay: "400",
    },
    {
      id: 12,
      image: "/foto_kelas/13.jpg",
      nama: "Muhammad Nurung",
      npm: "062230701529",
      tugas: "data/Muhammad Nurung/6_Software_Muhammad_Nurung.pdf", // Tambahkan informasi tugas di sini
      delay: "",
    },
    {
      id: 13,
      image: "/foto_kelas/14.jpg",
      nama: "Mutia Putri Azzahra",
      npm: "062230701530",
      tugas: "data/Mutia Putri Azzahra/Mutia Putri Azzahra_Tugas 1.pdf", // Tambahkan informasi tugas di sini
      delay: "200",
    },
    {
      id: 14,
      image: "/foto_kelas/15.jpg",
      nama: "Naik Hembang Krismas",
      npm: "062230701418",
      tugas: "data/Naik Hembang Krismas/NaikHembangKrismas_4CF_Perawatan&PerbaikanKomputer.pdf", // Tambahkan informasi tugas di sini
      delay: "400",
    },
    {
      id: 15,
      image: "/foto_kelas/16.jpg",
      nama: "Nanda Yulanda",
      npm: "062230701531",
      tugas: "data/Nanda Yulanda/NandaYulanda Tugas-1.pdf", // Tambahkan informasi tugas di sini
      delay: "",
    },
    {
      id: 16,
      image: "/foto_kelas/17.jpg",
      nama: "Nazeerah aladawiyah",
      npm: "062230701532",
      tugas: "data/Nazeerah aladawiyah/Nazeerah aladawiyah_6 software untuk menganalisa komputer.pdf", // Tambahkan informasi tugas di sini
      delay: "200",
    },
    {
      id: 17,
      image: "/foto_kelas/18.jpg",
      nama: "Putri Nadhiyah Salsabila",
      npm: "062230701533",
      tugas: "data/Putri Nadhiyah Salsabila/Putri Nadhiyah Tugas 1.pdf", // Tambahkan informasi tugas di sini
      delay: "400",
    },
    {
      id: 18,
      image: "/foto_kelas/19.jpg",
      nama: "Putri Saqinah Azelea",
      npm: "062130701745",
      tugas: "data/Putri Saqinah Azelea/6Software_PutriShaqinahAzelea.pdf", // Tambahkan informasi tugas di sini
      delay: "",
    },
    {
      id: 19,
      image: "/foto_kelas/20.jpg",
      nama: "Ramdan",
      npm: "062230701534",
      tugas: "data/Ramdan/Ramdan Tugas 1.pdf", // Tambahkan informasi tugas di sini
      delay: "200",
    },
    {
      id: 20,
      image: "/foto_kelas/21.jpg",
      nama: "Sabrina Citra",
      npm: "062230701536",
      tugas: "data/Sabrina Citra/SabrinaCitra_6 Software Untuk Menganalisa Komputer.pdf", // Tambahkan informasi tugas di sini
      delay: "400",
    },
    {
      id: 21,
      image: "/foto_kelas/22.jpg",
      nama: "Sekar Nurizki Anggraini",
      npm: "062230701537",
      tugas: "data/Sekar Nurizki Anggraini/062230701537_Sekar Nurizki Anggraini.pdf", // Tambahkan informasi tugas di sini
      delay: "",
    },
    {
      id: 22,
      image: "/foto_kelas/23.jpg",
      nama: "Tito Listanto",
      npm: "062230701538",
      tugas: "data/Tito Listanto/062230701538_Tito Listanto.pdf", // Tambahkan informasi tugas di sini
      delay: "200",
    },
    {
      id: 23,
      image: "/foto_kelas/24.jpg",
      nama: "Vinalia",
      npm: "062230701539",
      tugas: "data/Vinalia/Vinalia_Tugas1.pdf", // Tambahkan informasi tugas di sini
      delay: "400",
    },
  ];
  