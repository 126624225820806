import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { ProjekSemester3 } from '../data/mahasiswa.js';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

const Projek1Page = () => {
  return (
    <>
      <div className="projek-page">
        <div className="projek w-100 min-vh-100">
          <Container>
            <Row>
              <h1 className='fw-bold text-center animate__animated animate__fadeInUp animate__delay-1s'>Semua Projek Semester 3</h1>
              <p className='text-center animate__animated animate__fadeInUp animate__delay-1s'>Kumpulan Projek Semester 3 akhir Kelas 3CF</p>
            </Row>
            <Row className='mt-3'>
                {ProjekSemester3.filter(projekse3 => projekse3.id >= 1 && projekse3.id <= 12).map((projekse3) => (
                  <Col lg={4} sm={7} md={5} key={projekse3.npm} className="mb-3 text-center" data-aos="fade-up" data-aos-duration="1000" data-aos-delay={projekse3.delay}> {/* Menambahkan class text-center */}
                    <Card style={{ width: '18rem', display: 'inline-block' }}> {/* Menambahkan style display: 'inline-block' */}
                      <Card.Img variant="top" src={projekse3.image} alt={projekse3.nama} />
                      <Card.Body>
                        <Card.Title className='text-center'>{projekse3.kelompok}</Card.Title>
                        <Card.Title className='text-center' style={{fontWeight: 'bold', textTransform: 'uppercase'}}>
                          {projekse3.namaweb}
                        </Card.Title>
                        <Card.Text className='text-start'>
                          ‧ {projekse3.nama1} <br />
                          ‧ {projekse3.nama2} <br />
                        </Card.Text>
                        <Card.Text className='descripsi-se3'>
                          {projekse3.deskripsi}
                        </Card.Text>
                        <Button variant="primary" onClick={() => window.open(projekse3.link, '_blank')}>View Page</Button>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
            </Row>
          </Container>
        </div>
      </div>
    </>
  )
}

export default Projek1Page
