import React from 'react'

const FooterConponent = () => {
    return (
        <>
            <footer class="bg-dark text-light text-center py-3 fixed-bottom">
                <div class="container">
                    <div class="d-flex justify-content-center footer-container">
                        <div class="footer-text">
                            &copy; 2024 Projek Web Kelas 3 CF Politeknik Negeri Sriwijaya ‧ Support by ゆいちゃん |
                            &copy; 2024 Projek Web Kelas 3 CF Politeknik Negeri Sriwijaya ‧ Support by ゆいちゃん |
                            &copy; 2024 Projek Web Kelas 3 CF Politeknik Negeri Sriwijaya ‧ Support by ゆいちゃん 
                        </div>
                    </div>
                </div>
            </footer>
        </>
    )
}

export default FooterConponent
