import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { tugas1 } from '../data/perkom1.js';

import Card from 'react-bootstrap/Card';
import Button from 'react-bootstrap/Button';

function perkom1() {
  return (
    <>
        <div className="perkom-page">
            <div className="perkom w-100 min-vh-100">
                <Container>
                    <Row>
                        <h1 className='fw-bold text-center animate__animated animate__fadeInUp animate__delay-1s'>Tugas Perawatan dan Perbaikan Komputer</h1>
                        <p className='text-center animate__animated animate__fadeInUp animate__delay-1s'>6 Software Untuk Mengetahui Kerusakan Komputer dan Laptop</p>
                    </Row>
                    <Row>
                        {tugas1.filter(kelas => kelas.id >= 1 && kelas.id <= 24).map((kelas) => (
                            <Col lg={4} sm={7} md={5} key={kelas.npm} className="mb-1 text-center" data-aos="fade-up" data-aos-duration="1000" data-aos-delay={kelas.delay}> {/* Menambahkan class text-center */}
                                <Card style={{ width: '18rem', display: 'inline-block' }}> {/* Menambahkan style display: 'inline-block' */}
                                <Card.Img variant="top" src={kelas.image} alt={kelas.nama} />
                                <Card.Body>
                                    <Card.Title>{kelas.nama}</Card.Title>
                                    <Card.Text>{kelas.npm}</Card.Text>
                                    <Button variant="primary" onClick={() => window.open(kelas.tugas ? kelas.tugas : '', '_blank')} download>
                                        View Tugas
                                    </Button>
                                </Card.Body>
                                </Card>
                            </Col>
                        ))}
                    </Row>
                </Container>
            </div>
        </div>
    </>
  )
}

export default perkom1
