import React from 'react';
import {Container, Row, Col} from 'react-bootstrap';
import { DataMahasiswa } from '../data/mahasiswa.js';

import Card from 'react-bootstrap/Card';

const KelasPage = () => {
  return (
    <>
    <div className="kelas-page">
      <div className='kelas w-100 min-vh-100'>
        <Container>
          <Row>
            <Col>
              <h1 className='fw-bold text-center animate__animated animate__fadeInUp animate__delay-1s'>Daftar Mahasiswa</h1>
              <p className='text-center animate__animated animate__fadeInUp animate__delay-1s'>Semua daftar mahasiswa kelas 3CF Politeknik Negeri Sriwijaya</p>
            </Col>
          </Row>
          <Row className='mt-1'>
            {DataMahasiswa.filter(kelas => kelas.id >= 1 && kelas.id <= 24).map((kelas) => (
              <Col lg={4} sm={7} md={5} key={kelas.npm} className="mb-1 text-center" data-aos="fade-up" data-aos-duration="1000" data-aos-delay={kelas.delay}> {/* Menambahkan class text-center */}
                <Card style={{ width: '18rem', display: 'inline-block' }}> {/* Menambahkan style display: 'inline-block' */}
                  <Card.Img variant="top" src={kelas.image} alt={kelas.nama} />
                  <Card.Body>
                    <Card.Title>{kelas.nama}</Card.Title>
                    <Card.Text>{kelas.npm}</Card.Text>
                  </Card.Body>
                </Card>
              </Col>
            ))}
          </Row>
        </Container>
      </div>
    </div>
    </>
  )
}

export default KelasPage
