// HomePage.js
import React from 'react';

import Card from 'react-bootstrap/Card';
import { Link } from 'react-router-dom';
import {Container, Row, Col} from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

import 'animate.css/animate.min.css';

import { DataMahasiswa, ProjekSemester3 } from "../data/mahasiswa.js";
import {useNavigate} from 'react-router-dom';

// swiper
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/grid';
import 'swiper/css/pagination';


// import required modules
import { Autoplay, Grid, Pagination } from 'swiper/modules';

const HomePage = () => {
  let navigate = useNavigate();
  return (
    <>
      <div className='homepage' >
        <header className='header-home-page w-100 min-vh-100 header-box d-flex align-items-center justify-content-center text-center overflow-hidden'>
          <div className='row row-cols-1 row-cols-md-3 g-4 py-5 mt-3' style={{ display: 'flex', justifyContent: 'center' }}>
          <Card style={{ width: '18rem' }} className="card1 m-3 animate__animated animate__zoomInUp animate__delay-1s" >
            <Card.Img variant="top" src="https://media.istockphoto.com/id/1339879736/id/foto/tangan-seorang-wanita-menulis-janji-di-penyelenggara-buku-harian-atau-kalender-jadwal-dengan.jpg?s=1024x1024&w=is&k=20&c=yD_74dDP2PIIJiTAdmv5VsPRXnREqMhc-c3ELVzLsNY=" />
            <Card.Body>
              <Card.Title>Daftar Mahasiswa</Card.Title>
              <Card.Text>
                Daftar mahasiswa kelas 3CF angkatan 22 
              </Card.Text>
              <Link className="btn btn-primary" to="/mahasiswa">Daftar Mahasiswa</Link>
            </Card.Body>
          </Card>
          <Card style={{ width: '18rem' }} className="card2 m-3 animate__animated animate__zoomInUp animate__delay-1s" >
            <Card.Img variant="top" src="https://media.istockphoto.com/id/1130022936/id/foto/gambar-sketsa-tata-letak-pengembangan-situs-web.jpg?s=1024x1024&w=is&k=20&c=dgvCnqK-DWm_2LShmkR5S--2GVFzY1fcsRDL7b3D6rU=" />
            <Card.Body>
              <Card.Title>Projek Web semester 3</Card.Title>
              <Card.Text>
                Kumpulan Projek Web semester 3 Kelas 3CF angkatan 22 
              </Card.Text>
              <Link className="btn btn-primary" to="/projek">Lihat Projek</Link>
            </Card.Body>
          </Card>
          </div>
        </header>
        <section className='kelas'>
          <h1 className='text-center fw-bold'>Daftar Mahasiswa</h1>
          <Swiper
            grid={{
              rows: 1,
            }}
            autoplay={{
              delay: 2500,
              disableOnInteraction: false,
            }}
            spaceBetween={10}
            pagination={{
              clickable: true,
            }}
            breakpoints={{
              640: {
                slidesPerView: 1,
                spaceBetween: 40
              },
              768: {
                slidesPerView: 3,
                spaceBetween: 30
              },
              1024:{
                slidesPerView: 4,
                spaceBetween: 10
              },
              1200:{
                slidesPerView: 4,
                spaceBetween: 10
              }
            }}
            modules={[Autoplay, Grid, Pagination]}
            className="mySwiper"
            data-aos="fade-up"
            data-aos-anchor-placement="bottom-bottom"
          >
            {DataMahasiswa.map((kelasswiper) => {
              return <SwiperSlide  key={kelasswiper.id} >
                <Card.Img src={kelasswiper.image} alt={kelasswiper.nama} />
              </SwiperSlide>
            })}
          </Swiper>
          <Row>
            <Col className='text-center'>
              <button className='btn btn-primary rounded-5 btn-lg' onClick={() => navigate("/Mahasiswa")} data-aos="fade-up">
                Lihat Semua Mahasiswa <i className='fa-solid fa-chevron-right'></i>
              </button>
            </Col>
          </Row>
        </section>
        <section>
          <div className='projekse3 w-100 min-vh-100 card-img'>
          <Container className="card-img">  
            <h1 className='text-center fw-bold'>Projek Semester 3</h1>
              <Row className='mt-3'>
                {ProjekSemester3.filter(projekse3 => projekse3.id >= 1 && projekse3.id <= 6).map((projekse3) => (
                  <Col lg={4} sm={7} md={5} key={projekse3.npm} className="mb-5 text-center" data-aos="fade-up"
                  data-aos-anchor-placement="bottom-bottom"
                  data-aos-duration="1000" data-aos-delay={projekse3.delay}> {/* Menambahkan class text-center */}
                    <Card style={{ width: '18rem', display: 'inline-block' }}> {/* Menambahkan style display: 'inline-block' */}
                      <Card.Img variant="top" src={projekse3.image} alt={projekse3.nama} />
                      <Card.Body>
                        <Card.Title className='text-center'>{projekse3.kelompok}</Card.Title>
                        <Card.Title className='text-center' style={{fontWeight: 'bold', textTransform: 'uppercase'}}>
                          {projekse3.namaweb}
                        </Card.Title>
                        <Card.Text className='text-start'>
                          ‧ {projekse3.nama1} <br />
                          ‧ {projekse3.nama2} <br />
                        </Card.Text>
                        <Card.Text className='descripsi-se3'>
                          {projekse3.deskripsi}
                        </Card.Text>
                        <Button variant="primary" onClick={() => window.open(projekse3.link, '_blank')}>View Page</Button>
                      </Card.Body>
                    </Card>
                  </Col>
                ))}
              </Row>
              <Row>
                <Col className='text-center mt-5'>
                  <button className='btn btn-primary rounded-5 btn-lg' onClick={() => navigate("/projek")} data-aos="fade-up">
                    Lihat Semua Projek <i className='fa-solid fa-chevron-right'></i>
                  </button>
                </Col>
              </Row>
            </Container>
          </div>
        </section>
      </div>
    </>
  )
}

export default HomePage