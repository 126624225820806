import React from 'react';
import { useState, useEffect } from 'react';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink } from "react-router-dom";

const NavbarComponent = () => {
    const [ changColor, setChangColor ] = useState(false);

    const changBackgroundColor = () => {
        if(window.scrollY > 5){
            setChangColor(true);
        } else {
            setChangColor(false);
        }
    };

    useEffect(() => {
        changBackgroundColor();

        window.addEventListener('scroll', changBackgroundColor);
    });

    return (
        <>
            <div>

                <Navbar expand="lg" className={changColor ? "color-active fixed-top" : "fixed-top"} >
                    <Container>
                        <Navbar.Brand href="/" className='navbar-brand'>
                            <img
                            alt="CFPOLSRI"
                            src="cfpolsri.png"
                            width="30"
                            height="30"
                            className="d-inline-block align-top"
                            />{' '}
                            CF POLSRI
                        </Navbar.Brand>
                        <Navbar.Toggle aria-controls="basic-navbar-nav" />
                        <Navbar.Collapse id="basic-navbar-nav">
                            <Nav className="mx-auto nav-link text-center">
                                <NavLink to="/" 
                                    className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                } end>
                                        Home
                                </NavLink>
                                <NavLink to="/mahasiswa"
                                    className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                } end>
                                    Daftar Mahasiswa
                                </NavLink>
                                {/* <NavLink to="/projek"
                                    className={({ isActive, isPending }) =>
                                    isPending ? "pending" : isActive ? "active" : ""
                                } end>
                                    Projek Semester 3
                                </NavLink> */}
                                <NavDropdown title="Tugas" id="basic-nav-dropdown"> 
                                    <NavDropdown.Item as={NavLink} to="/projek"> 
                                        Projek Semester 3
                                    </NavDropdown.Item>
                                    <NavDropdown.Item as={NavLink} to="/Perkom"> 
                                        Tugas Perkom 1
                                    </NavDropdown.Item>
                                </NavDropdown>
                            </Nav>
                        </Navbar.Collapse>
                    </Container>
                </Navbar>
            </div>
        </>
    )
}

export default NavbarComponent
