export const DataMahasiswa = [
    {
      id: 1,
      image: "/foto_kelas/1.jpg",
      nama: "CFMEMBER",
      npm: "",
      delay: "",
    },
    {
      id: 2,
      image: "/foto_kelas/2.jpg",
      nama: "Adhitya Pramana Putra",
      npm: "062230701516",
      delay: "200",
    },
    
    {
      id: 3,
      image: "/foto_kelas/3.jpg",
      nama: "Ahmad Perdian",
      npm: "062230701517",
      delay: "400",
    },
    {
      id: 4,
      image: "/foto_kelas/4.jpg",
      nama: "Arif Wijaya",
      npm: "062230701519",
      delay: "",
    },
    {
      id: 5,
      image: "/foto_kelas/5.jpg",
      nama: "Clodiya Novita Sari",
      npm: "062230701520",
      delay: "200",
    },
    {
      id: 6,
      image: "/foto_kelas/6.jpg",
      nama: "Elda Oktarina",
      npm: "062230701521",
      delay: "400",
    },
    {
      id: 7,
      image: "/foto_kelas/7.jpg",
      nama: "Fauzan Ramadhan",
      npm: "062230701522",
      delay: "",
    },
    {
      id: 8,
      image: "/foto_kelas/8.jpg",
      nama: "Hari Wijaya",
      npm: "062230701523",
      delay: "200",
    },
    {
      id: 9,
      image: "/foto_kelas/9.jpg",
      nama: "M.Zakky Al Wafa",
      npm: "062230701525",
      delay: "400",
    },
    {
      id: 10,
      image: "/foto_kelas/10.jpg",
      nama: "Ghibran Adean",
      npm: "062230701526",
      delay: "",
    },
    {
      id: 11,
      image: "/foto_kelas/11.jpg",
      nama: "M Ade Ilham W",
      npm: "062230701527",
      delay: "200",
    },
    {
      id: 12,
      image: "/foto_kelas/12.jpg",
      nama: "Muhammad Farhan",
      npm: "062230701528",
      delay: "400",
    },
    {
      id: 13,
      image: "/foto_kelas/13.jpg",
      nama: "Muhammad Nurung",
      npm: "062230701529",
      delay: "",
    },
    {
      id: 14,
      image: "/foto_kelas/14.jpg",
      nama: "Mutia Putri Azzahra",
      npm: "062230701530",
      delay: "200",
    },
    {
      id: 15,
      image: "/foto_kelas/15.jpg",
      nama: "Naik Hembang Krismas",
      npm: "062230701418",
      delay: "400",
    },
    {
      id: 16,
      image: "/foto_kelas/16.jpg",
      nama: "Nanda Yulanda",
      npm: "062230701531",
      delay: "",
    },
    {
      id: 17,
      image: "/foto_kelas/17.jpg",
      nama: "Nazeerah aladawiyah",
      npm: "062230701532",
      delay: "200",
    },
    {
      id: 18,
      image: "/foto_kelas/18.jpg",
      nama: "Putri Nadhiyah Salsabila",
      npm: "062230701533",
      delay: "400",
    },
    {
      id: 19,
      image: "/foto_kelas/19.jpg",
      nama: "Putri Saqinah Azelea",
      npm: "062130701745",
      delay: "",
    },
    {
      id: 20,
      image: "/foto_kelas/20.jpg",
      nama: "Ramdan",
      npm: "062230701534",
      delay: "200",
    },
    {
      id: 21,
      image: "/foto_kelas/21.jpg",
      nama: "Sabrina Citra",
      npm: "062230701536",
      delay: "400",
    },
    {
      id: 22,
      image: "/foto_kelas/22.jpg",
      nama: "Sekar Nurizki Anggraini",
      npm: "062230701537",
      delay: "",
    },
    {
      id: 23,
      image: "/foto_kelas/23.jpg",
      nama: "Tito Listanto",
      npm: "062230701538",
      delay: "200",
    },
    {
      id: 24,
      image: "/foto_kelas/24.jpg",
      nama: "Vinalia",
      npm: "062230701539",
      delay: "400",
    },
  ];

export const ProjekSemester3 = [
  {
    id: 1,
    kelompok: "KELOMPOK 1",
    nama1: "Sekar Nurizki Anggraini",
    nama2: "Tito Listanto",
    namaweb: "tscarcare",
    image: "/foto_web/1.png",
    link: "https://tscarcare.cfpolsri.my.id/",
    deskripsi: "Semua yang alami dan berkualitas tinggi ada di kami, enjoy your shoping",
    popovers1: "NPM Sekar : 062230701537",
    popovers2: "NPM Tito : 062230701538",
    delay: ""
  },
  {
    id: 2,
    kelompok: "KELOMPOK 2",
    nama1: "Nanda Yulanda",
    nama2: "Ghibran Adean",
    namaweb: "jeepjourney",
    image: "/foto_web/2.png",
    link: "https://jeepjourney.cfpolsri.my.id/",
    deskripsi: "Destinasi utama Anda untuk menemukan mobil Jeep berkualitas tinggi dengan harga terjangkau.",
    popovers1: "NPM Nanda : 062230701540",
    popovers2: "NPM Ghibran : 062230701526",
    delay: "200"
  },
  {
    id: 3,
    kelompok: "KELOMPOK 3",
    nama1: "Muhammad Ade Ilham Wahyudi",
    nama2: "Arif Wijaya",
    namaweb: "videoku",
    image: "/foto_web/3.png",
    link: "https://videoku.cfpolsri.my.id/",
    deskripsi: "Website tentang Streaming Video dengan nama web VideoKu",
    popovers1: "NPM Ade : 062230701527",
    popovers2: "NPM Aif : 062230701519",
    delay: "400",
  },
  {
    id: 4,
    kelompok: "KELOMPOK 4",
    nama1: "Ahmad Perdian",
    nama2: "Muhammad Farhan",
    namaweb: "hanmad",
    image: "/foto_web/4.png",
    link: "https://hanmad.cfpolsri.my.id/",
    deskripsi: "Nikmati koleksi game berkualitas untuk berbagai platform, dari Windows hingga Xbox.",
    popovers1: "NPM Ahmad : 062230701517",
    popovers2: "NPM Ahmad : 062230701528",
    delay: ""
  },
  {
    id: 5,
    kelompok: "KELOMPOK 5",
    nama1: "Adhitya Pramana Putra",
    nama2: "Ramdan",
    namaweb: "adram-tiket",
    image: "/foto_web/5.png",
    link: "http://adram-tiket.cfpolsri.my.id/",
    deskripsi: "Cek tiket Kamu secara online dengan mudah dan cepat. Temukan penawaran terbaik untuk perjalanan Kamu di Adram-tiket.",
    popovers1: "NPM Adhit : 062230701516",
    popovers2: "NPM Ramdan : 062230710515",
    delay: "200",
  },
  {
    id: 6,
    kelompok: "KELOMPOK 6",
    nama1: "Clodiya Novita Sari",
    nama2: "Vinalia",
    namaweb: "sweetbakery",
    image: "/foto_web/6.png",
    link: "https://sweetbakery.cfpolsri.my.id/",
    deskripsi: "Kami adalah destinasi utama bagi pecinta kue yang mencari pengalaman rasa yang tak terlupakan.",
    popovers1:  "NPM Clodya : 10118234",
    popovers2: "NPM Vinalia : 062230701539",
    delay: "400"
  },
  {
    id: 7,
    kelompok: "KELOMPOK 7",
    nama1: "Muhammad Nurung",
    nama2: "Hari Wijaya",
    namaweb: "runkjaysteam",
    image: "/foto_web/7.png",
    link: "https://runkjaysteam.cfpolsri.my.id/",
    deskripsi: "Mau main game berkualitas tapi ga mampu beli? disini tempatnya.",
    popovers1: "NPM Nurung : 062230701529",
    popovers2: "NPM Hari : 062230701523",
    delay: "",
  },
  {
    id: 8,
    kelompok: "KELOMPOK 8",
    nama1: "Naik Hembang Krismas",
    nama2: "M.Zakky Al Wafa",
    namaweb: "pecellele",
    image: "/foto_web/8.png",
    link: "http://pecellele.cfpolsri.my.id/",
    deskripsi: "Nikamati makan pecellele tanpa dikatain kampungan",
    popovers1: "NPM Hembang : 062230701418",
    popovers2: "NPM Zakky : 062230701525",
    delay: "200",
  },
  {
    id: 9,
    kelompok: "KELOMPOK 9",
    nama1: "Sabrina Citra",
    nama2: "Putri Nadhiyah Salsabila",
    namaweb: "lavida",
    image: "/foto_web/9.png",
    link: "https://lavida.cfpolsri.my.id/",
    deskripsi: "online shop dengan nama web La Vida",
    popovers1: "NPM Sabrina : 062230701536",
    popovers2: "NPM Nadhiyah : 062230701537",
    delay: "400",
  },
  {
    id: 10,
    kelompok: "KELOMPOK 10",
    nama1: "Putri Saqinah Azelea",
    nama2: "Elda Oktarina",
    namaweb: "kedaiajelda",
    image: "/foto_web/10.png",
    link: "http://kedaiajelda.cfpolsri.my.id/",
    deskripsi: "Pempek autentik di Kedai Ajelda: varian rasa beragam, belanja online praktis!",
    popovers1: "NPM Saqinah : 062230701539",
    popovers2: "NPM Elda : 062230701538",
    delay: "",
  },
  {
    id: 11,
    kelompok: "KELOMPOK 11",
    nama1: "Nazeerah aladawiyah",
    nama2: "Mutia Putri Azzahra",
    namaweb: "Technoid lolipop",
    image: "/foto_web/11.png",
    link: "https://cfpolsri.my.id/native/technoid/user/index.php",
    deskripsi: "Penjualanan accesoris hp dengan nama Technoid lolipop",
    popovers1: "NPM Nazeera : 062230701540",
    popovers2: "NPM Mutia : 062230701541",
    delay: "200"
  },
  {
    id: 12,
    kelompok: "KELOMPOK 12",
    nama1: "Fauzan Ramadhan",
    nama2: "",
    namaweb: "dapoersboenda",
    image: "/foto_web/12.png",
    link: "https://dapoersboenda.cfpolsri.my.id/",
    deskripsi: "Temukan dan nikmati kelezatan kue pempek kreasi Palembang di Dapoer Boenda",
    popovers1: "NPM Fauzan : 062230701542",
    popovers2: "",
    delay: "400"
  },
] 