import "./App.css";
import { Route, Routes } from 'react-router-dom';
import Projek1Page from "./pages/Projek1Page";
import KelasPage from "./pages/KelasPage";
import HomePage from "./pages/HomePage";
import Perkom from "./pages/Perkom";
import NavbarComponent from './components/NavbarComponent';
import FooterConponent from './components/FooterConponent';
import AOS from 'aos';
import 'aos/dist/aos.css'; 
function App() {
  AOS.init();
  return (
    <>
      <NavbarComponent/>

      <div className="app">
        <Routes>
          <Route path="/" Component={HomePage}/>
          <Route path="/Mahasiswa" Component={KelasPage}/>
          <Route path="/projek" Component={Projek1Page}/>
          <Route path="/Perkom" Component={Perkom}/>
        </Routes>
      </div>

      <FooterConponent/>
    </>
  );
}

export default App;
